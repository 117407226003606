@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.background-image-329 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background: radial-gradient(46.74% 56.2% at 31.59% 62.71%, rgba(248, 248, 247, 0) 0%, #F8F8F7 100%);
}

.usn-page-selected-tab-shadow {
  box-shadow: 0px 1px 2px 0px #1C1C1C0F, 0px 1px 3px 0px #1C1C1C1A;
}

.usn-page-whitelist-status-tooltip-shadow {
  box-shadow: 0px 4px 4px 0px #6F7DB90D, 0px 4px 6px -2px #28101008, 0px 12px 16px -4px #2810100A;
}

.usn-page-main-container-shadow {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.action-card-shadow {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.mint-redeem-requests-carousel-next-overlay-dark {
  background: linear-gradient(270deg, #0C0A09 0%, rgba(12, 10, 9, 0) 100%);
}

.mint-redeem-requests-carousel-previous-overlay-dark {
  background: linear-gradient(90deg, #0C0A09 0%, rgba(12, 10, 9, 0) 100%);
}

.mint-redeem-requests-carousel-next-overlay {
  background: linear-gradient(270deg, #FAFAF9 0%, rgba(250, 250, 249, 0) 100%);
}

.mint-redeem-requests-carousel-previous-overlay {
  background: linear-gradient(90deg, #FAFAF9 0%, rgba(250, 250, 249, 0) 100%);
}

.token-selector-dropdown-button-shadow {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.token-selector-dropdown-container-shadow {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.stake-container-shadow {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.stake-tab-shadow {
  box-shadow: 0px 1px 2px 0px #1C1C1C0F, 0px 1px 3px 0px #1C1C1C1A;
}

.buy-tab-design {
  box-shadow: 0px 1px 2px 0px #1C1C1C0F, 0px 1px 3px 0px #1C1C1C1A;
}

.withdraw-scroll-top-overlay-dark {
  background: linear-gradient(180deg, #0C0A09 11.52%, rgba(12, 10, 9, 0) 100%);
}

.withdraw-scroll-top-overlay {
  background: linear-gradient(180deg, #FFFFFF 11.52%, rgba(255, 255, 255, 0) 100%);
}

.tab-shadow {
  box-shadow: 0px 1px 2px 0px #1C1C1C0F, 0px 1px 3px 0px #1C1C1C1A;
}

.coming-soon-light {
  background: linear-gradient(90deg, #FFF4F0 0%, #FFEDEB 53.04%, #F1EEFF 100%);
}

.coming-soon-dark {
  background: linear-gradient(90deg, #281912 0%, #3F2321 53.04%, #201B35 100%);
}

.points-estimate-calc {
  background: linear-gradient(90deg, #FBD8C9 0%, #F7D8D5 53.04%, #DDD9ED 100%);
}

.points-estimate-calc-dark {
  background: linear-gradient(90deg, #563D32 0%, #553734 53.04%, #4E466F 100%);
}

.referral-modal-icon-shadow {
  box-shadow: 0px 0.6px 0.91px 0px #3D000026, 0px 1.81px 1.81px 0px #3D000021, 0px 4.23px 2.42px 0px #3D000014, 0px 7.55px 3.02px 0px #3D000005, 0px 11.77px 3.32px 0px #3D000000;
}

.referral-modal-icons-bg {
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
    linear-gradient(0deg, rgba(255, 78, 14, 0.1), rgba(255, 78, 14, 0.1));
}

.referral-info-table-header-bg {
  background: linear-gradient(90deg, #FBD8C9 0%, #F7D8D5 53.04%, #DDD9ED 100%);
}

.referral-info-table-header-bg-dark {
  background: linear-gradient(90deg, #563D32 0%, #553734 53.04%, #4E466F 100%);
}

.table-row {
  border-radius: 12px !important;
}
