.data-container {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.table-container {
  box-shadow: 0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A, 0px 0px 0px 1px #1018280A;
}

.timeframe-selected-conatiner {
  box-shadow: 0px 3px 3.8px 0px #1516181C;
}

.table-pagination-selected-page-shadow {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.dashboard-personalized-info-section {
  box-shadow: 0px 4px 6px -2px #28101008, 0px 12px 16px -4px #2810100A, 0px 0px 0px 2px #2810100A;
}

.dashboard-points-menu-button {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.dashboard-points-menu-list-container {
  box-shadow: 0px 4px 4px 0px #6F7DB90D, 0px 4px 6px -2px #28101008, 0px 12px 16px -4px #2810100A;
}

.dashboard-points-estimate-modal {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.dashboard-points-estimate-modal-period-selection {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.dashboard-profile-edit-modal {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}


.top-row-box-shadow {
  box-shadow: 0px 4px 6px -2px #28101008, 0px 12px 16px -4px #2810100A, 0px 0px 0px 2px #2810100A;
}

.referral-box-shadows {
  box-shadow: 0px 4px 6px -2px #28101008, 0px 12px 16px -4px #2810100A, 0px 0px 0px 2px #2810100A;
}

.container-shadow {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.table-footer {
  background: linear-gradient(90deg, #E6E9FF 0%, #FFECF4 29.91%, #FFFFFF 89.74%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
}

.table-footer-dark {
  background: linear-gradient(90deg, #E6E9FF 0%, #FFECF4 29.91%, #000000 89.74%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
}

.subtotal-bg {
  background: linear-gradient(90deg, #FFF6F1 0%, #FFF3F2 53.04%, #F7F5FF 100%);
}

.subtotal-dark-bg {
  background: linear-gradient(90deg, #251A15 0%, #2A1917 53.04%, #201B35 100%);
}

.total-data-field-dark-bg {
  background: linear-gradient(90deg, #563D32 0%, #553734 53.04%, #4E466F 100%);
}

.total-data-field-bg {
  background: linear-gradient(90deg, #FFEEE6 0%, #FFD6D3 53.04%, #E4DDFF 100%),
    linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}

.table-section-bg {
  background: linear-gradient(90deg, #E6E9FF 0%, #FFECF4 29.91%, #FFFFFF 89.74%),
      linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
}

.leaderboard-container {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.leaderboard-table-overlay-dark {
  background: linear-gradient(180deg, rgba(28, 25, 23, 0) 0%, #1C1917 100%);
}

.leaderboard-table-overlay {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
}
