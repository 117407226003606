.bridge-confirm-toolip-container-shadow {
  box-shadow:
    0px 1.2px 2.4px 0px #21273214,
    0px -3.6px 0px 0px #21273226 inset;
}

.bridge-main-container-shadow {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.bridge-main-network-double-arrow-icon {
  box-shadow: 0px 3.2px 3.2px 0px #6F7DB90D;
}

.bridge-currency-selector-shadow {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.bridge-swap-icon {
  box-shadow: 0px 5.6px 5.6px 0px #6F7DB90D;
}

.bridge-success-icon {
  box-shadow: 0px 1.2px 2.4px 0px #21273247, 0px -3.6px 0px 0px #21273226 inset;
}

.bridge-elipsis-38 {
  backdrop-filter: blur(177.6783905029297px);
}

.network-selection-modal-shadow {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}
