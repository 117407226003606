.redeem-page-container-shadow {
  box-shadow: 0px 4px 4px 0px #6F7DB90D;
}

.redeem-requests-carousel-next-overlay {
  background: linear-gradient(270deg, #FAFAF9 0%, rgba(250, 250, 249, 0) 100%);
}

.redeem-requests-carousel-previous-overlay {
  background: linear-gradient(90deg, #FAFAF9 0%, rgba(250, 250, 249, 0) 100%);
}
